import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {styled} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {removeUndefined} from '@shared/lib/type_utils';
import {SanitizedItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Autocomplete} from '@shared-frontend/components/core/autocomplete';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {largeInputTheme} from '@src/components/core/theme';
import {HoobiizMediaView} from '@src/components/ui/hoobiiz_media_view';
import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import {HoobiizSearchLocation} from '@src/components/ui/hoobiiz_search_location';
import {getDiscount} from '@src/lib/discount';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';

interface SearchBarProps {
  className?: string;
}

export const SearchBar: Custom<SearchBarProps, 'div'> = props => {
  const {className} = props;
  const navigate = useNavigate();
  const location = useLocation();

  const lookupItem = useCallback(async (query: string) => {
    const res = await apiCall(HoobiizApi, '/search-activity', {query});
    return res.activities;
  }, []);

  const renderItem = useCallback((item: SanitizedItem<'HoobiizActivity'>, highlighted: boolean) => {
    const ticketInfo = item.bestStock?.stock?.availableTickets[0];
    const {amount} = getDiscount(ticketInfo, removeUndefined([item.bestStock?.offer]));

    return (
      <SearchResult $highlighted={highlighted}>
        <SearchResultMedia media={item.media[0]} size={{width: 80, height: 80}} />
        <SearchResultContent>
          <SearchResultTitle>{item.label}</SearchResultTitle>
          <SearchResultSubtitle>{item.vendor?.name}</SearchResultSubtitle>
          {ticketInfo ? (
            <HoobiizPricesView prices={amount} prefix="Dès" justify="flex-start" />
          ) : (
            // Out of stock
            EmptyFragment
          )}
        </SearchResultContent>
      </SearchResult>
    );
  }, []);

  const itemToInputString = useCallback((item: SanitizedItem<'HoobiizActivity'>) => item.label, []);
  const itemToKey = useCallback((item: SanitizedItem<'HoobiizActivity'>) => item.id, []);

  const handleSelect = useCallback(
    (item?: SanitizedItem<'HoobiizActivity'>) => {
      if (item) {
        navigate(getActivityUrl(item));
      }
    },
    [navigate]
  );

  return (
    <Wrapper>
      <Autocomplete
        lookupItem={lookupItem}
        minChar={3}
        overrides={largeInputTheme}
        itemElement={renderItem}
        itemToInputString={itemToInputString}
        itemToKey={itemToKey}
        syncState={handleSelect}
        autoFocus={location.pathname === '/'}
        // eslint-disable-next-line react/forbid-component-props
        className={className}
      />
      <HoobiizSearchLocation />
    </Wrapper>
  );
};
SearchBar.displayName = 'SearchBar';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const SearchResult = styled.div<{$highlighted: boolean}>`
  display: flex;
  gap: 8px;
  background-color: ${p => (p.$highlighted ? '#eeeeee' : '#ffffff')};
  padding: 8px;
  &:hover {
    background-color: #eeeeee;
  }
`;

const SearchResultMedia = styled(HoobiizMediaView)`
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
`;
const SearchResultContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SearchResultTitle = styled.div`
  font-size: 18px;
`;
const SearchResultSubtitle = styled.div`
  font-size: 15px;
  color: #666666;
`;
