import {FC} from 'react';
import styled from 'styled-components';

import {removeUndefined} from '@shared/lib/type_utils';
import {SanitizedItem} from '@shared/model/search_tables';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {Colors, FontWeight} from '@src/components/core/theme_base';
import {HoobiizMediaView} from '@src/components/ui/hoobiiz_media_view';
import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import {discountToLongString, getDiscount} from '@src/lib/discount';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';

interface HoobiizActivityCardProps {
  activity: SanitizedItem<'HoobiizActivity'>;
  clickable: boolean;
}

export const HoobiizActivityCard: FC<HoobiizActivityCardProps> = ({activity, clickable}) => {
  const {id, label, media, vendor, bestStock, cse} = activity;

  const address = vendor?.addresses[0]?.address;
  let locationLabel = EmptyFragment;
  if (address) {
    locationLabel = (
      <LocationLabel>
        <SvgIcon name="LocationPin" color={Colors.DarkGrey} size={15} />
        <div>{address.addressDetails.city}</div>
        <div>{`(${
          // For France, we show postal code, for other countries, the name of the country
          address.addressDetails.country === 'France' &&
          address.addressDetails.postalCode !== undefined
            ? address.addressDetails.postalCode.slice(0, 2)
            : address.addressDetails.country
        })`}</div>
      </LocationLabel>
    );
  }

  let cseLabel = EmptyFragment;
  if (cse) {
    cseLabel = <CseLabel>Offre réservée aux CSE</CseLabel>;
  }

  const ticketInfo = bestStock?.stock?.availableTickets[0];
  const {amount, totalDiscount} = getDiscount(ticketInfo, removeUndefined([bestStock?.offer]));

  const priceComponent = ticketInfo ? (
    <HoobiizPricesView prices={amount} prefix="Dès" justify="center" />
  ) : (
    // Out of stock
    EmptyFragment
  );

  const card = (
    <Card>
      <ImageAndLabels>
        <HoobiizMediaView media={media[0]} height={200} />
        {totalDiscount.percent > 0 ? (
          <DiscountLabel>{discountToLongString(totalDiscount)}</DiscountLabel>
        ) : (
          EmptyFragment
        )}
        {locationLabel}
        {cseLabel}
      </ImageAndLabels>
      <Info>
        <Type>{vendor?.name}</Type>
        <Title title={label}>{label}</Title>
        {priceComponent}
      </Info>
    </Card>
  );

  return clickable ? (
    <StyledNavLink to={getActivityUrl(activity)} key={id}>
      {card}
    </StyledNavLink>
  ) : (
    <Wrapper>{card}</Wrapper>
  );
};
HoobiizActivityCard.displayName = 'HoobiizActivityCard';

const StyledNavLink = styled(UnthemedNavLink)`
  display: block;
  width: 100%;
  max-width: 300px;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${Colors.LightGrey2};
  overflow: hidden;
  height: 100%;
`;

export const DummyCard = styled.div`
  width: 230px;
  height: 300px;
`;

const ImageAndLabels = styled.div`
  position: relative;
`;

const LocationLabel = styled.div`
  position: absolute;
  right: 0;
  top: 8px;

  display: flex;
  align-items: center;
  gap: 4px;

  background-color: #ffffffbb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 5px;

  color: ${Colors.DarkGrey};
  font-size: 12px;
  font-weight: ${FontWeight.Bold700};
`;

const CseLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 30px;
  display: inline-block;
  color: white;
  background-color: ${Colors.Gold};
  padding: 5px;
  font-size: 12px;
  font-weight: ${FontWeight.Bold700};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const DiscountLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
  color: ${Colors.Green};
  background-color: ${Colors.LightGreen};
  padding: 5px;
  font-size: 12px;
  font-weight: ${FontWeight.Bold700};
  border-top-left-radius: 4px;
`;

const Info = styled.div`
  padding: 10px;
`;

const Type = styled.div`
  text-transform: uppercase;
  color: ${Colors.Grey};
  font-weight: ${FontWeight.Bold700};
  font-size: 14px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-weight: ${FontWeight.Bold700};
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
